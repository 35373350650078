<template>
  <ClientOnly>
    <Teleport to="body">
      <!-- Generic Add to trolley modal -->
      <template v-if="isMobile">
        <TsDrawer
          data-testid="add-to-trolley-generic"
          v-model:visible="trolleyStore.preview_overlay_visible"
          position="bottom"
          append-class="bg-transparent overflow-hidden"
        >
          <TsModalAddToTrolley
            v-if="trolleyStore.previewed_product"
            :collection-availability="trolleyStore.collection_availability"
            @set-branch="handleSetBranchFromAddToTrolleyModal"
            :selected-variant-label="
              trolleyStore.previewed_product.pack_size ||
              trolleyStore.previewed_product.name
            "
            :product="trolleyStore.previewed_product"
            :loading="trolleyStore.is_updating_item_details_in_add_to_trolley"
            :data-testid="`add-to-trolley-${trolleyStore.previewed_product.code}`"
            @add-to-trolley="(q: number) => handleAddToTrolleyAction(q)"
            @close="trolleyStore.preview_overlay_visible = false"
            @click-variants="handleVariantClickFromAddToTrolleyModal"
            @increment="
              (quantity) => handleQuantityChangeFromAddToTrolleyModal(quantity)
            "
            @decrement="
              (quantity) => handleQuantityChangeFromAddToTrolleyModal(quantity)
            "
            @change="
              (quantity) => handleQuantityChangeFromAddToTrolleyModal(quantity)
            "
            @input="
              (quantity) => handleQuantityChangeFromAddToTrolleyModal(quantity)
            "
          >
            <template #cta>
              <TsRow
                v-if="trolleyStore.is_added"
                append-class="m-0 justify-between p-2"
              >
                <TsColumn>
                  <TsButton
                    block
                    :label="continueShoppingCTALabel"
                    size="sm"
                    outlined
                    data-testid="continue-shopping-add-to-trolley-modal"
                    @click="trolleyStore.preview_overlay_visible = false"
                  />
                </TsColumn>
                <TsColumn>
                  <TsButton
                    block
                    icon="mdi:trolley-outline"
                    :label="goToTrolleyCTALabel"
                    data-testid="go-to-trolley-page-add-to-trolley-modal"
                    @click="handleTrolleyPageNavigation"
                    size="sm"
                  />
                </TsColumn>
              </TsRow>
            </template>
          </TsModalAddToTrolley>
        </TsDrawer>
      </template>
      <template v-if="isDesktop">
        <TsModal
          data-testid="add-to-trolley-generic"
          v-model:visible="trolleyStore.preview_overlay_visible"
          append-class="max-h-[80vh] bg-transparent overflow-hidden"
        >
          <template #modal-content>
            <TsModalAddToTrolley
              v-if="trolleyStore.previewed_product"
              :collection-availability="trolleyStore.collection_availability"
              @set-branch="handleSetBranchFromAddToTrolleyModal"
              :selected-variant-label="
                trolleyStore.previewed_product.pack_size ||
                trolleyStore.previewed_product.name
              "
              :product="trolleyStore.previewed_product"
              :loading="trolleyStore.is_updating_item_details_in_add_to_trolley"
              :data-testid="`add-to-trolley-${trolleyStore.previewed_product.code}`"
              @add-to-trolley="(q: number) => handleAddToTrolleyAction(q)"
              @close="trolleyStore.preview_overlay_visible = false"
              @click-variants="handleVariantClickFromAddToTrolleyModal"
              @increment="
                (quantity) =>
                  handleQuantityChangeFromAddToTrolleyModal(quantity)
              "
              @decrement="
                (quantity) =>
                  handleQuantityChangeFromAddToTrolleyModal(quantity)
              "
              @change="
                (quantity) =>
                  handleQuantityChangeFromAddToTrolleyModal(quantity)
              "
              @input="
                (quantity) =>
                  handleQuantityChangeFromAddToTrolleyModal(quantity)
              "
            >
              <template #cta>
                <TsRow
                  v-if="trolleyStore.is_added"
                  append-class="m-0 justify-between p-2"
                >
                  <TsColumn>
                    <TsButton
                      block
                      :label="continueShoppingCTALabel"
                      size="sm"
                      outlined
                      data-testid="continue-shopping-add-to-trolley-modal"
                      @click="trolleyStore.preview_overlay_visible = false"
                    />
                  </TsColumn>
                  <TsColumn>
                    <TsButton
                      block
                      icon="mdi:trolley-outline"
                      :label="goToTrolleyCTALabel"
                      data-testid="go-to-trolley-page-add-to-trolley-modal"
                      @click="handleTrolleyPageNavigation"
                      size="sm"
                    />
                  </TsColumn>
                </TsRow>
              </template>
            </TsModalAddToTrolley>
          </template>
        </TsModal>
      </template>

      <!-- Collection modal -->
      <template v-if="isMobile">
        <TsDrawer
          data-testid="collection-preview"
          v-model:visible="trolleyStore.preview_overlay_collection_visible"
          position="bottom"
          append-class="bg-transparent overflow-hidden"
        >
          <TsModalTrolleyCollection
            :loading="trolleyStore.currentTrolleyAction === 'Collection'"
            @set-branch="handleSetBranchFromCollectionModal"
            @close="trolleyStore.preview_overlay_collection_visible = false"
            @go-to-trolley="handleTrolleyPageNavigation"
            @find-stock="
              (requiredQuantity) => findNearByStock(requiredQuantity)
            "
          />
        </TsDrawer>
      </template>

      <template v-if="isDesktop">
        <TsModal
          data-testid="collection-preview"
          v-model:visible="trolleyStore.preview_overlay_collection_visible"
          append-class="bg-transparent overflow-hidden"
        >
          <template #modal-content>
            <TsModalTrolleyCollection
              :loading="trolleyStore.currentTrolleyAction === 'Collection'"
              @set-branch="handleSetBranchFromCollectionModal"
              @close="trolleyStore.preview_overlay_collection_visible = false"
              @go-to-trolley="handleTrolleyPageNavigation"
              @find-stock="
                (requiredQuantity) => findNearByStock(requiredQuantity)
              "
            />
          </template>
        </TsModal>
      </template>

      <!-- Delivery modal -->
      <template v-if="isMobile">
        <TsDrawer
          data-testid="delivery-modal"
          v-model:visible="trolleyStore.preview_overlay_delivery_visible"
          position="bottom"
          append-class="bg-transparent overflow-hidden"
        >
          <TsModalTrolleyDelivery
            :loading="trolleyStore.currentTrolleyAction === 'Delivery'"
            @close="trolleyStore.preview_overlay_delivery_visible = false"
            @go-to-trolley="handleTrolleyPageNavigation"
          />
        </TsDrawer>
      </template>

      <template v-if="isDesktop">
        <TsModal
          data-testid="delivery-modal"
          v-model:visible="trolleyStore.preview_overlay_delivery_visible"
          append-class="bg-transparent overflow-hidden"
        >
          <template #modal-content>
            <TsModalTrolleyDelivery
              :loading="trolleyStore.currentTrolleyAction === 'Delivery'"
              @close="trolleyStore.preview_overlay_delivery_visible = false"
              @go-to-trolley="handleTrolleyPageNavigation"
            />
          </template>
        </TsModal>
      </template>

      <!-- Delivery variants modal -->
      <template v-if="isMobile">
        <TsDrawer
          data-testid="delivery-variants"
          v-model:visible="trolleyStore.delivery_variants_modal_visible"
          position="bottom"
          append-class="bg-transparent overflow-hidden"
        >
          <TsModalTrolleyDeliveryVariants
            :label="
              isVariantsAddedToTrolley ? 'Added to trolley' : 'Add to trolley'
            "
            :is-loading="variantAddToTrolleyLoading"
            :append-class="
              twJoin(
                'mt-4 gap-1.5 mx-auto max-w-sm',
                isVariantsAddedToTrolley &&
                  'bg-success hover:bg-success-hover border-success text-idle-white'
              )
            "
            :disabled="isVariantsAddedToTrolley"
            @close="trolleyStore.delivery_variants_modal_visible = false"
            @add-all-to-trolley="
              (variantsToAdd) => addAllDeliveryVariantsToTrolley(variantsToAdd)
            "
          />
        </TsDrawer>
      </template>

      <template v-if="isDesktop">
        <TsModal
          data-testid="delivery-variants"
          append-class="bg-transparent overflow-hidden"
          v-model:visible="trolleyStore.delivery_variants_modal_visible"
        >
          <template #modal-content>
            <TsModalTrolleyDeliveryVariants
              :label="
                isVariantsAddedToTrolley ? 'Added to trolley' : 'Add to trolley'
              "
              :is-loading="variantAddToTrolleyLoading"
              :append-class="
                twJoin(
                  'mt-4 gap-1.5 mx-auto max-w-sm',
                  isVariantsAddedToTrolley &&
                    'bg-success hover:bg-success-hover border-success text-idle-white'
                )
              "
              :disabled="isVariantsAddedToTrolley"
              @close="trolleyStore.delivery_variants_modal_visible = false"
              @add-all-to-trolley="
                (variantsToAdd) =>
                  addAllDeliveryVariantsToTrolley(variantsToAdd)
              "
            />
          </template>
        </TsModal>
      </template>

      <!-- Collection variants modal -->
      <template v-if="isMobile">
        <TsDrawer
          data-testid="collection-variants"
          v-model:visible="trolleyStore.collection_variants_modal_visible"
          position="bottom"
          append-class="bg-transparent overflow-hidden"
        >
          <TsModalTrolleyCollectionVariants
            :label="
              isVariantsAddedToTrolley ? 'Added to trolley' : 'Add to trolley'
            "
            :is-loading="variantAddToTrolleyLoading"
            :append-class="
              twJoin(
                'mt-4 gap-1.5 mx-auto max-w-sm',
                isVariantsAddedToTrolley &&
                  'bg-success hover:bg-success-hover border-success text-idle-white'
              )
            "
            :disabled="isVariantsAddedToTrolley"
            @close="trolleyStore.collection_variants_modal_visible = false"
            @add-all-to-trolley="
              (variantsToAdd) =>
                addAllCollectionVariantsToTrolley(variantsToAdd)
            "
          />
        </TsDrawer>
      </template>

      <template v-if="isDesktop">
        <TsModal
          data-testid="collection-variants"
          append-class="bg-transparent overflow-hidden"
          v-model:visible="trolleyStore.collection_variants_modal_visible"
        >
          <template #modal-content>
            <TsModalTrolleyCollectionVariants
              :label="
                isVariantsAddedToTrolley ? 'Added to trolley' : 'Add to trolley'
              "
              :is-loading="variantAddToTrolleyLoading"
              :append-class="
                twJoin(
                  'mt-4 gap-1.5 mx-auto max-w-sm',
                  isVariantsAddedToTrolley &&
                    'bg-success hover:bg-success-hover border-success text-idle-white'
                )
              "
              :disabled="isVariantsAddedToTrolley"
              @close="trolleyStore.collection_variants_modal_visible = false"
              @add-all-to-trolley="
                (variantsToAdd) =>
                  addAllCollectionVariantsToTrolley(variantsToAdd)
              "
            />
          </template>
        </TsModal>
      </template>

      <!-- Notify me modal -->
      <template v-if="isMobile">
        <TsDrawer
          v-model:visible="trolleyStore.subscribe_notification_modal_visible"
          data-testid="subscribe-notification-modal"
          position="bottom"
          append-class="bg-transparent overflow-hidden min-h-48"
        >
          <TsModalOutOfStockSubscription
            @close="trolleyStore.subscribe_notification_modal_visible = false"
            @subscribe="
              trolleyStore.subscribe_notification_modal_visible = false
            "
          />
        </TsDrawer>
      </template>

      <template v-if="isDesktop">
        <TsModal
          v-model:visible="trolleyStore.subscribe_notification_modal_visible"
          data-testid="subscribe-notification-modal"
          append-class="max-w-[450px]"
        >
          <template #modal-content>
            <TsModalOutOfStockSubscription
              @close="trolleyStore.subscribe_notification_modal_visible = false"
              @subscribe="
                trolleyStore.subscribe_notification_modal_visible = false
              "
            />
          </template>
        </TsModal>
      </template>

      <!-- Product card variants modal -->
      <template v-if="isMobile">
        <TsDrawer
          data-testid="product-variant-modal"
          v-model:visible="trolleyStore.product_variants_modal_visible"
          position="bottom"
          append-class="bg-transparent overflow-hidden"
        >
          <TsModalProductVariants
            v-if="
              trolleyStore.previewed_product_v2 ||
              trolleyStore.previewed_product
            "
            :main-product="
              trolleyStore.previewed_product_v2 ||
              trolleyStore.previewed_product
            "
            :variants="trolleyStore.previewed_product_variations"
            @close="trolleyStore.product_variants_modal_visible = false"
            @select="
              (selectedVariant) =>
                handleProductVariantSelection(selectedVariant)
            "
            @notify="trolleyStore.product_variants_modal_visible = false"
          />
        </TsDrawer>
      </template>

      <template v-if="isDesktop">
        <TsModal
          data-testid="product-variant-modal"
          v-model:visible="trolleyStore.product_variants_modal_visible"
          append-class="bg-transparent overflow-hidden"
        >
          <template #modal-content>
            <TsModalProductVariants
              v-if="
                trolleyStore.previewed_product_v2 ||
                trolleyStore.previewed_product
              "
              :main-product="
                trolleyStore.previewed_product_v2 ||
                trolleyStore.previewed_product
              "
              :variants="trolleyStore.previewed_product_variations"
              @close="trolleyStore.product_variants_modal_visible = false"
              @select="
                (selectedVariant) =>
                  handleProductVariantSelection(selectedVariant)
              "
              @notify="trolleyStore.product_variants_modal_visible = false"
            />
          </template>
        </TsModal>
      </template>

      <!-- Product card variants with both CTAs modal -->
      <template v-if="isMobile">
        <TsDrawer
          data-testid=""
          v-model:visible="
            trolleyStore.preview_collection_delivery_modal_visible
          "
          position="bottom"
          append-class="bg-transparent overflow-hidden top-20"
        >
          <TsModalTrolleyCollectionDeliveryCTA
            @add-to-trolley="
              (productCode, quantity, channel) =>
                handleAddToTrolleyFromBothCTAModal(
                  productCode,
                  quantity,
                  channel
                )
            "
            @notify="
              trolleyStore.preview_collection_delivery_modal_visible = false
            "
            @set-branch="handleSetBranchFromBothCTAModal"
            :loading-cta="trolleyStore.activeTrolleyCTAInModal"
            :loading="trolleyStore.currentTrolleyAction === 'BothCTA'"
            v-if="
              trolleyStore.previewed_product_v2 ||
              trolleyStore.previewed_product
            "
            :product="
              trolleyStore.previewed_product_v2 ||
              trolleyStore.previewed_product
            "
            v-model:quantity="selectedProductVariantQuantity"
            @close="
              trolleyStore.preview_collection_delivery_modal_visible = false
            "
          />
        </TsDrawer>
      </template>

      <template v-if="isDesktop">
        <TsModal
          data-testid=""
          append-class="max-w-[450px]"
          v-model:visible="
            trolleyStore.preview_collection_delivery_modal_visible
          "
        >
          <template #modal-content>
            <TsModalTrolleyCollectionDeliveryCTA
              @add-to-trolley="
                (productCode, quantity, channel) =>
                  handleAddToTrolleyFromBothCTAModal(
                    productCode,
                    quantity,
                    channel
                  )
              "
              @notify="
                trolleyStore.preview_collection_delivery_modal_visible = false
              "
              @set-branch="handleSetBranchFromBothCTAModal"
              :loading-cta="trolleyStore.activeTrolleyCTAInModal"
              :loading="trolleyStore.currentTrolleyAction === 'BothCTA'"
              v-if="
                trolleyStore.previewed_product_v2 ||
                trolleyStore.previewed_product
              "
              :product="
                trolleyStore.previewed_product_v2 ||
                trolleyStore.previewed_product
              "
              v-model:quantity="selectedProductVariantQuantity"
              @close="
                trolleyStore.preview_collection_delivery_modal_visible = false
              "
            />
          </template>
        </TsModal>
      </template>

      <!-- Confirmation modal -->
      <template v-if="isMobile">
        <TsDrawer
          data-testid=""
          v-model:visible="
            trolleyStore.multiple_items_confirmation_modal_visible
          "
          position="bottom"
          append-class="bg-transparent overflow-hidden top-20"
        >
          <TsModalTrolleyMultipleItemsConfirmation
            data-testid="multiple-items-confirmation-ui"
            :products="trolleyStore.variant_items_added_to_trolley"
            :branch="branchStore.lastSavedBranch?.name"
            @close="
              trolleyStore.multiple_items_confirmation_modal_visible = false
            "
            @notify="
              trolleyStore.multiple_items_confirmation_modal_visible = false
            "
            @go-to-trolley="handleTrolleyPageNavigation"
          />
        </TsDrawer>
      </template>
      <template v-if="isDesktop">
        <TsModal
          data-testid=""
          v-model:visible="
            trolleyStore.multiple_items_confirmation_modal_visible
          "
          position="bottom"
          append-class="bg-transparent overflow-hidden top-20"
        >
          <template #modal-content>
            <TsModalTrolleyMultipleItemsConfirmation
              data-testid="multiple-items-confirmation-ui"
              :products="trolleyStore.variant_items_added_to_trolley"
              :branch="branchStore.lastSavedBranch?.name"
              @close="
                trolleyStore.multiple_items_confirmation_modal_visible = false
              "
              @notify="
                trolleyStore.multiple_items_confirmation_modal_visible = false
              "
              @go-to-trolley="handleTrolleyPageNavigation"
            />
          </template>
        </TsModal>
      </template>

      <!-- Nearby stock search modal -->
      <template v-if="isMobile">
        <TsDrawer
          data-testid=""
          v-model:visible="stockStore.stock_finder_modal_visible"
          position="bottom"
          append-class="bg-transparent overflow-hidden top-20"
        >
          <template #default="{ drawer }">
            <TsModalNearbyStockFinder
              @add-for-collection="
                (branch, quantity) => handleBuyNowCollection(branch, quantity)
              "
              :loading="stockStore.is_nearby_stock_search_loading"
              @select-locality-suggestion="
            (selectedSuggestion) => onSelectSuggestion(selectedSuggestion?.public_id as string)
          "
              @select-current-location="handleCurrentLocationStockSearch"
              @close="drawer?.hide()"
            />
          </template>
        </TsDrawer>
      </template>
      <template v-if="isDesktop">
        <TsModal
          data-testid=""
          v-model:visible="stockStore.stock_finder_modal_visible"
          position="bottom"
          append-class="bg-transparent max-w-[460px] overflow-hidden top-20"
        >
          <template #modal-content="{ modal }">
            <TsModalNearbyStockFinder
              @add-for-collection="
                (branch, quantity) => handleBuyNowCollection(branch, quantity)
              "
              :loading="stockStore.is_nearby_stock_search_loading"
              @select-locality-suggestion="
              (selectedSuggestion) => onSelectSuggestion(selectedSuggestion?.public_id as string)
            "
              @select-current-location="handleCurrentLocationStockSearch"
              @close="modal?.hide()"
            />
          </template>
        </TsModal>
      </template>
      <!-- Product comparison drawer -->
      <template v-if="compareStore.isCompareRowVisible">
        <div
          class="fixed bottom-0 left-0 right-0 bg-white z-30 shadow-[-2px_-3px_7.5px_2px_#02294E29]"
        >
          <TsCompareRow
            :items="compareStore.checkedProduts"
            @remove-item="handleRemoveItem"
            @clear-all="handleClearAll"
          />
        </div>
      </template>
      <TsDrawer
        data-testid=""
        position="bottom"
        v-model:visible="compareStore.enableExpand"
        append-class="bg-natural-soft-white overflow-y-auto h-full"
      >
        <TsSection
          v-if="compareStore.checkedProduts.length && compareStore.enableExpand"
          container-class="max-md:px-0"
        >
          <TsSectionProductCompare @close="compareStore.enableExpand = false" />
        </TsSection>
      </TsDrawer>
    </Teleport>
  </ClientOnly>
</template>

<script lang="ts" setup>
import { twJoin } from "tailwind-merge";
import { DeliveryMethodCodes } from "~/assets/constants/ecomApi";
import type { Branch } from "~/types/ecom/branch.type";
import type { Product } from "~/types/ecom/product/product.type";
import { TrolleyChannel } from "~/types/ecom/trolley/trolley-map";
import type { TrolleyLineRequest } from "~/types/ecom/trolley/trolley.type";

const continueShoppingCTALabel = useTranslation("continueShopping", "Continue shopping");
const goToTrolleyCTALabel = useTranslation("goToTrolley", "Go to trolley");

const localePath = useLocalePath();

const { isMobile, isDesktop } = useDevice();
const trolleyStore = useTrolleyStore();
const branchStore = useBranchStore();
const stockStore = useStockStore();
const compareStore = useCompareStore();

const handleRemoveItem = (item: Product) => {
  compareStore.checkedProduts = compareStore.checkedProduts?.filter(
    (p) => p.code !== item.code
  );
  if (!compareStore.checkedProduts.length)
    compareStore.isCompareRowVisible = false;
};

const handleClearAll = () => {
  compareStore.clearCompareProducts();
};

function hideAllListingPageModals() {
  trolleyStore.preview_overlay_delivery_visible = false;
  trolleyStore.preview_overlay_collection_visible = false;
  trolleyStore.multiple_items_confirmation_modal_visible = false;
  trolleyStore.preview_overlay_visible = false;
}

const variantAddToTrolleyLoading = ref(false);
const isVariantsAddedToTrolley = ref(false);
const selectedProductVariantQuantity = ref(1);

/* Add to trolley - generic */
async function handleAddToTrolleyAction(quantity: number) {
  // Direct ship delivery
  if (trolleyStore.previewed_product?.direct_ship) {
    trolleyStore.addToTrolleyForDirectship(
      trolleyStore.previewed_product.code,
      quantity
    );
    return;
  }

  // Next day collection
  if (trolleyStore.collection_availability?.status === "NextDayCollection") {
    trolleyStore.addToTrolleyForNextDayCollection(
      trolleyStore.previewed_product!.code,
      quantity
    );
    return;
  }

  // Collection
  if (
    trolleyStore.previewed_product_selected_channel ===
    TrolleyChannel.Collection
  ) {
    await trolleyStore.addToTrolleyForCollection(
      trolleyStore.previewed_product!.code,
      quantity
    );
    return;
  }

  // Delivery
  await trolleyStore.addToTrolleyForDelivery(
    trolleyStore.previewed_product!.code,
    quantity
  );
}

async function handleQuantityChangeFromAddToTrolleyModal(
  chosen_quantity: number
) {
  trolleyStore.is_added = false;
  if (!branchStore.is_branch_set) return;

  const product = trolleyStore.previewed_product;

  // Ensure product exists before checking its properties
  if (!product) return;

  const { stockDetails } = product;

  if (!stockDetails) return;

  const { collection, delivery } = stockDetails;

  // enable next day collection
  if (chosen_quantity > collection! && chosen_quantity <= delivery!) {
    trolleyStore.collection_availability = {
      status: "NextDayCollection",
      stock: delivery!,
    };
    return;
  }
  // enable oos
  else if (chosen_quantity > collection! && chosen_quantity > delivery!) {
    trolleyStore.collection_availability = {
      status: "OutOfStock",
      stock: 0,
    };
  }
  // go back to collection if neither is true
  else {
    trolleyStore.collection_availability = {
      status: "Collection",
      stock: collection!,
    };
  }
}

async function handleVariantClickFromAddToTrolleyModal() {
  trolleyStore.preview_overlay_visible = false;
  trolleyStore.product_variants_modal_visible = true;
  await trolleyStore.getProductVariants(
    trolleyStore.previewed_product?.variations as string[]
  );
}

// common method to add multiple product variants to trolley
async function addVariantsToTrolley(variantsToAdd: TrolleyLineRequest[]) {
  variantAddToTrolleyLoading.value = true;
  await trolleyStore.addMultipleItemsToTrolley(variantsToAdd);
  variantAddToTrolleyLoading.value = false;
  isVariantsAddedToTrolley.value = true;
}

async function addAllCollectionVariantsToTrolley(
  collectionVariantsToAdd: TrolleyLineRequest[]
) {
  await addVariantsToTrolley(collectionVariantsToAdd);
  setTimeout(() => {
    isVariantsAddedToTrolley.value = false;
    setVariantItemsAddedToTrolley(collectionVariantsToAdd);
    trolleyStore.collection_variants_modal_visible = false;
    trolleyStore.multiple_items_confirmation_modal_visible = true;
    trolleyStore.collection_product_variants = [];
  }, 1000);
}

async function addAllDeliveryVariantsToTrolley(
  deliveryVariantsToAdd: TrolleyLineRequest[]
) {
  await addVariantsToTrolley(deliveryVariantsToAdd);
  setTimeout(() => {
    isVariantsAddedToTrolley.value = false;
    setVariantItemsAddedToTrolley(deliveryVariantsToAdd);
    trolleyStore.delivery_variants_modal_visible = false;
    trolleyStore.multiple_items_confirmation_modal_visible = true;
    trolleyStore.delivery_product_variants = [];
  }, 1000);
}

function setVariantItemsAddedToTrolley(items: TrolleyLineRequest[]) {
  trolleyStore.variant_items_added_to_trolley = Object.values(
    trolleyStore.trolley_line_items!
  )
    .flatMap((val) => val)
    .filter((line) =>
      items.some((item) => item.product_code === line.product_code)
    )
    .map((line) => {
      const matchingItem = items.find(
        (item) => item.product_code === line.product_code
      );
      return {
        ...line.product,
        quantity: matchingItem!.quantity,
        channel: line.channel,
      } as TrolleyItemVariant;
    });
}

function handleProductVariantSelection(selectedVariant: Product) {
  trolleyStore.product_variants_modal_visible = false;
  trolleyStore.previewed_product_v2 = selectedVariant;
  trolleyStore.preview_collection_delivery_modal_visible = true;
  // reset states
  selectedProductVariantQuantity.value = 1;
  trolleyStore.currentTrolleyAction = "";
  trolleyStore.activeTrolleyCTAInModal = "";
}

async function onChangeBranchFromTrolleyModals(
  modalType?: "collection" | "bothCTA"
) {
  // open the branch selector pop up
  branchStore.branchSelectorModalVisible = true;
  const unwatch = watch(
    () => [branchStore.lastSavedBranch],
    async () => {
      // clear watch handlers / side effects
      unwatch();
      if (modalType === "collection") {
        trolleyStore.preview_overlay_collection_visible = true;
        trolleyStore.currentTrolleyAction = "Collection";
      } else if (modalType === "bothCTA") {
        trolleyStore.preview_collection_delivery_modal_visible = true;
        trolleyStore.currentTrolleyAction = "BothCTA";
      }
      // recalculate stock for the previewed product
      const stockStore = useStockStore();
      const [productWithUpdatedStock] = await stockStore.getStockForProducts([
        trolleyStore.previewed_product_v2?.code!,
      ]);
      trolleyStore.previewed_product_v2 = productWithUpdatedStock;
      trolleyStore.currentTrolleyAction = "";
    }
  );
}

async function handleSetBranchFromBothCTAModal() {
  trolleyStore.preview_collection_delivery_modal_visible = false;
  await onChangeBranchFromTrolleyModals("bothCTA");
}

async function handleSetBranchFromCollectionModal() {
  trolleyStore.preview_overlay_collection_visible = false;
  await onChangeBranchFromTrolleyModals("collection");
}

async function handleSetBranchFromAddToTrolleyModal() {
  trolleyStore.preview_overlay_visible = false;
  branchStore.branchSelectorModalVisible = true;

  const unwatch = watch(
    () => [branchStore.lastSavedBranch],
    async () => {
      unwatch();
      trolleyStore.preview_overlay_visible = true;
      if (!trolleyStore.previewed_product) return;
      trolleyStore.collection_stock_loading = true;

      trolleyStore.collection_availability =
        await trolleyStore.evaluateCollectionAvailability(
          trolleyStore.previewed_product
        );

      trolleyStore.collection_stock_loading = false;
    }
  );
}

async function handleAddToTrolleyFromBothCTAModal(
  productCode: string,
  quantity: number,
  channel: TrolleyChannel
) {
  trolleyStore.variant_items_added_to_trolley = [];

  if (channel === TrolleyChannel.Collection)
    await trolleyStore.listenToBranchSet();

  trolleyStore.activeTrolleyCTAInModal = TrolleyChannel[channel];

  await trolleyStore.dispatchItemToTrolley(
    productCode,
    quantity,
    channel,
    //@ts-ignore
    DeliveryMethodCodes[TrolleyChannel[channel]]
  );

  trolleyStore.activeTrolleyCTAInModal = "";
  trolleyStore.preview_collection_delivery_modal_visible = false;
  trolleyStore.multiple_items_confirmation_modal_visible = true;
  trolleyStore.variant_items_added_to_trolley = [
    { ...trolleyStore.previewed_product_v2!, quantity, channel },
  ];
  selectedProductVariantQuantity.value = 1;
}

//-------------------- Find stock near by feature
async function findNearByStock(quantity: number) {
  if (!trolleyStore.previewed_product_v2) return;
  const currentBranch = branchStore.lastSavedBranch;

  if (!currentBranch) return;

  trolleyStore.preview_overlay_collection_visible = false;
  stockStore.nearby_stock_search_branch_choice = null;
  branchStore.localitySearchInput = currentBranch.name;
  stockStore.stock_finder_modal_visible = true;

  await handleNearByStockSearch(
    currentBranch.geolocation.latitude,
    currentBranch.geolocation.longitude,
    quantity
  );
}

async function onSelectSuggestion(publicId: string) {
  if (!trolleyStore.previewed_product_v2) return;
  const localityDetailsResult = await branchStore.getLocalityDetails(publicId);

  if (!localityDetailsResult || !localityDetailsResult.geometry) return;

  const latitude = String(localityDetailsResult.geometry.location.lat);
  const longitude = String(localityDetailsResult.geometry.location.lng);

  await handleNearByStockSearch(latitude, longitude, 1);
}

async function handleCurrentLocationStockSearch() {
  if (!branchStore.currentLocation) return;
  const latitude = branchStore.currentLocation.latitude;
  const longitude = branchStore.currentLocation.longitude;

  await handleNearByStockSearch(latitude, longitude, 1);
}

async function handleNearByStockSearch(
  latitude: string,
  longitude: string,
  requiredQuantity: number
) {
  if (!trolleyStore.previewed_product_v2) return;

  stockStore.is_nearby_stock_search_loading = true;

  const nearestBranchRequestBody = {
    latitude,
    longitude,
  };

  await stockStore.getNearByStock(
    trolleyStore.previewed_product_v2.code,
    requiredQuantity,
    nearestBranchRequestBody
  );

  stockStore.is_nearby_stock_search_loading = false;
}

async function handleBuyNowCollection(
  selectedBranch: Branch,
  quantity: number
) {
  if (!trolleyStore.previewed_product_v2) return;
  stockStore.stock_finder_modal_visible = false;
  branchStore.onSaveBranch(selectedBranch);

  /* TODO -----> BUY NOW COLLECTION FLOW - WILL ADD AFTER BUY NOW BRANCH MERGE */

  // const checkoutStore = useCheckoutStore();
  // await checkoutStore.addToBuyNowForCollection(selectedBranch, {
  //   product_code: trolleyStore.previewed_product_v2.code,
  //   quantity,
  //   channel: TrolleyChannel.Collection,
  //   delivery_method_code: DeliveryMethodCodes.Collection,
  // });
}

function handleTrolleyPageNavigation() {
  hideAllListingPageModals();
  navigateTo(localePath("/trolley"));
}
</script>
